import { createTheme } from '@mui/material/styles'

// Types
import type { Direction } from '@mui/material/styles'

// Fonts

const ThemeConfig = (dir: Direction) =>
	createTheme({
		direction: dir,
		cssVariables: {
			colorSchemeSelector: 'class'
		},
		palette: {
			primary: { light: '#b29ae3', main: '#6636c6', dark: '#3b0f67', contrastText: '#fff' },
			secondary: { light: '#f7f4ee', main: '#ffd000', dark: '#ffa800', contrastText: '#fff' },
			text: { primary: '#363738', secondary:"#787a7c"}
		},
		shape: { borderRadius: 8 },
		typography: {
			fontFamily: 'calibri',
			fontSize: 14,
		},
		components: {
			MuiCssBaseline: {
				styleOverrides: {
					body: { scrollBehavior: 'smooth' },
					a: { textDecoration: 'none' }
				}
			}
		},
		breakpoints: {
			values: {
				xs: 0,
				sm: 600,
				md: 900,
				lg: 1200,
				xl: 1536
			}
		}
	})

export default ThemeConfig
