'use client'

import { forwardRef } from 'react'
import { dir } from 'i18next'
import { SnackbarProvider as NotistackSnackbarProvider } from 'notistack'

// Types
import type { SnackbarItem, SnackbarProviderProps } from '@/types/components/providers/snackbar'

// Component
import AlertItemAtom from '@/components/atoms/items/alert'

const DefaultAlertItemAtom = forwardRef<HTMLDivElement, SnackbarItem>(({ id, message }: SnackbarItem, ref) => (
	<AlertItemAtom ref={ref} id={id} severity="info">
		{message}
	</AlertItemAtom>
))

const ErrorAlertItemAtom = forwardRef<HTMLDivElement, SnackbarItem>(({ id, message }: SnackbarItem, ref) => (
	<AlertItemAtom ref={ref} id={id} severity="error">
		{message}
	</AlertItemAtom>
))

const SuccessAlertItemAtom = forwardRef<HTMLDivElement, SnackbarItem>(({ id, message }: SnackbarItem, ref) => (
	<AlertItemAtom ref={ref} id={id} severity="success">
		{message}
	</AlertItemAtom>
))

const WarningAlertItemAtom = forwardRef<HTMLDivElement, SnackbarItem>(({ id, message }: SnackbarItem, ref) => (
	<AlertItemAtom ref={ref} id={id} severity="warning">
		{message}
	</AlertItemAtom>
))

const InfoAlertItemAtom = forwardRef<HTMLDivElement, SnackbarItem>(({ id, message }: SnackbarItem, ref) => (
	<AlertItemAtom ref={ref} id={id} severity="info">
		{message}
	</AlertItemAtom>
))

DefaultAlertItemAtom.displayName = 'DefaultAlertItemAtom'
ErrorAlertItemAtom.displayName = 'ErrorAlertItemAtom'
SuccessAlertItemAtom.displayName = 'SuccessAlertItemAtom'
WarningAlertItemAtom.displayName = 'WarningAlertItemAtom'
InfoAlertItemAtom.displayName = 'InfoAlertItemAtom'

const SnackbarProvider = (props: SnackbarProviderProps) => {
	// Props
	const { lng, children } = props

	// Variables
	const langDir = dir(lng)

	return (
		<NotistackSnackbarProvider
			maxSnack={3}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: langDir === 'rtl' ? 'left' : 'right'
			}}
			Components={{
				default: DefaultAlertItemAtom,
				error: ErrorAlertItemAtom,
				success: SuccessAlertItemAtom,
				warning: WarningAlertItemAtom,
				info: InfoAlertItemAtom
			}}
		>
			{children}
		</NotistackSnackbarProvider>
	)
}

export default SnackbarProvider
