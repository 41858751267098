'use client'

import { alpha } from '@mui/material/styles'
import { Alert, IconButton } from '@mui/material'

import {
	ErrorTwoTone as ErrorTwoToneIcon,
	CheckCircleTwoTone as CheckCircleTwoToneIcon,
	WarningTwoTone as WarningTwoToneIcon,
	InfoTwoTone as InfoTwoToneIcon,
	CloseTwoTone as CloseTwoToneIcon
} from '@mui/icons-material'

import { closeSnackbar } from 'notistack'

// Types
import type { AlertItemProps } from '@/types/components/atoms/items/alert'

const AlertItemAtom = (props: AlertItemProps) => {
	// Props
	const { ref, id, severity, children } = props

	return (
		<Alert
			ref={ref}
			variant="filled"
			severity={severity}
			iconMapping={{
				error: <ErrorTwoToneIcon fontSize="inherit" />,
				success: <CheckCircleTwoToneIcon fontSize="inherit" />,
				warning: <WarningTwoToneIcon fontSize="inherit" />,
				info: <InfoTwoToneIcon fontSize="inherit" />
			}}
			action={
				<IconButton
					size="small"
					color="inherit"
					aria-label="close"
					onClick={() => closeSnackbar(id)}
					sx={{
						borderRadius: 1,
						'& .MuiTouchRipple-root .MuiTouchRipple-child': { borderRadius: 1 }
					}}
				>
					<CloseTwoToneIcon fontSize="small" />
				</IconButton>
			}
			sx={theme => ({
				bgcolor:
					severity === 'success'
						? alpha(theme.palette.success.main, 0.7)
						: severity === 'info'
							? alpha(theme.palette.info.main, 0.7)
							: severity === 'warning'
								? alpha(theme.palette.warning.main, 0.7)
								: alpha(theme.palette.error.main, 0.7),
				backdropFilter: 'blur(5px)'
			})}
		>
			{children}
		</Alert>
	)
}

export default AlertItemAtom
